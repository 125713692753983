<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-user-new" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li
                  v-for="error in errors"
                  v-bind:key="error"
                  v-html="error"
                ></li>
              </ul>
            </div>
            <div v-if="isSuccess" class="alert alert-success">
              {{
                $t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-6">
              <label for="FirstName" class="form-label required"
                >{{
                  $t(
                    "Users.FirstName",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="FirstName"
                v-model="userData.firstName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="LastName" class="form-label required"
                >{{
                  $t(
                    "Users.LastName",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <textarea
                type="text"
                class="form-control"
                id="LastName"
                rows="1"
                v-model="userData.lastName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              ></textarea>
            </div>
            <div class="col-md-6">
              <label for="Email" class="form-label required"
                >{{
                  $t(
                    "Users.Email",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="email"
                class="form-control"
                id="Email"
                v-model="userData.email"
                :spellcheck="this.$isTextSpellCheck"
                :autocomplete="this.$isAutoComplete"
                required
              />
            </div>
            <div class="col-md-3">
              <label for="Password" class="form-label required"
                >{{
                  $t(
                    "Projects.Password",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="password"
                class="form-control"
                id="Password"
                @keyup="validatePassword()"
                v-model="userData.password"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-3">
              <label for="PasswordAgain" class="form-label required"
                >{{
                  $t(
                    "Projects.PasswordAgain",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
                <small class="text-danger" v-if="passwordDontMatch"
                  >({{
                    $t(
                      "BaseModelFields.PasswordDontMatch",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }})</small
                ></label
              >
              <input
                type="password"
                class="form-control"
                @keyup="validatePassword()"
                id="PasswordAgain"
                v-model="passwordAgain"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="UserGroups" class="form-label"
                >{{
                  $t(
                    "Users.UserGroups",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                @onMultipleSelectChange="onChangeUserGroups"
                :isMultiple="true"
                :isParameters="true"
                :isGatewayRequest="true"
                :requestUrl="
                  String.format(
                    '/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup'
                  )
                "
              />
            </div>
            <div class="col-md-6">
              <label for="PermissionGroups" class="form-label"
                >{{
                  $t(
                    "Users.PermissionGroups",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                @onMultipleSelectChange="onChangePermissionGroups"
                :isMultiple="true"
                :isParameters="true"
                :isGatewayRequest="true"
                :requestUrl="
                  String.format(
                    '/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup'
                  )
                "
              />
            </div>
            <div class="col-md-6">
              <label for="ActiveMenu" class="form-label"
                >{{
                  $t(
                    "Users.ActiveMenu",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                :isDetailIconShow="true"
                :routeLink="String.format('#/Menu/ListItem/')"
                @onChange="onChangeActiveMenu"
                :isGatewayRequest="true"
                requestUrl="/Lcdp-SummaryMenus"
              />
            </div>
            <div class="col-12">
              <div class="form-check">
                <label for="IsApiUser" class="form-label">{{
                  $t(
                    "Users.IsApiUser",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsApiUser"
                  :spellcheck="this.$isTextSpellCheck"
                  v-model="userData.isApiUser"
                />
              </div>
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="userData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmit('0', 'btn-save')"
              @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
              @clear="onClear()"
              :isNewRecord="true"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "UserNew",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      userData: {
        isActive: true,
      },
      isSuccess: false,
      passwordDontMatch: false,
      passwordAgain: "",
    };
  },
  methods: {
    validatePassword() {
      this.passwordDontMatch = this.userData.password !== this.passwordAgain;
      if (this.passwordDontMatch) {
        $("#PasswordAgain").addClass("is-invalid");
        $("#PasswordAgain").removeClass("is-valid");
      } else {
        $("#PasswordAgain").removeClass("is-invalid");
        $("#PasswordAgain").addClass("is-valid");
      }
    },
    onClear() {
      this.userData = {
        isActive: true,
      };
      $(".form-user-new").removeClass("was-validated");
    },
    onChangeActiveMenu(selected) {
      this.userData.activeMenuId = selected.key;
      this.userData.activeMenuName = selected.value;
    },
    onChangeUserGroups(ids) {
      this.userData.userGroupPublicIds = ids;
    },
    onChangePermissionGroups(ids) {
      this.userData.permissionGroupPublicIds = ids;
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-user-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        this.passwordDontMatch ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        if (this.passwordDontMatch) {
          this.validatePassword();
        }
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      var model = { ...this.userData };
      model.email = this.$root.encrypt(model.email, true);
      model.password = this.$root.encrypt(model.password, true);

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-NewUser", model)
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push("/User/List");
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
};
</script>
